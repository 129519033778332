import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  shareWidgetContainer: css<{ isMobile: boolean; isFundraiser: boolean }>`
    ${baseStyles.shareWidgetContainer}
  `,
  textContainer: css`
    ${baseStyles.textContainer}
  `,
  iconGroupContainer: css`
    ${baseStyles.iconGroupContainer}
  `,
  iconContainer: css`
    ${baseStyles.iconContainer}
  `
};
