import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventHeaderContainer: css<{ renewal: boolean }>`
    flex-direction: row;
    margin-bottom: 6px;
    width: 100%;
    justify-content: flex-start;
    padding-horizontal: ${({ renewal }) => (renewal ? '26px' : '32px')};
  `,
  eventHeaderLeftSide: css<{ isMobile: boolean }>`
    margin-right: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
  `,
  eventHeaderRightSide: css<{ isMobile: boolean }>`
    flex: 1 1 0%;
  `,
  numberOfEventsWrapper: css<{ isMobile: boolean }>`
    background-color: #323232;
    border-radius: 12px;
    height: 18px;
    justify-content: center;
    margin-top: ${({ isMobile }) => (isMobile ? '8px' : '0px')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '8px' : '0px')};
    padding-left: 8px;
    padding-right: 8px;
    align-self: flex-start;
  `,
  scheduleWrapper: css`
    min-height: 20px;
    ${(props: any) => {
      return css`
        ${props.isMobile
          ? 'flex-direction: column;'
          : `flex-direction: row;
             margin-right: 24px;
            `};
      `;
    }}
  `,
  ticketsRemainingWrapper: css`
    background-color: 'rgb(255,250,205)';
    border-radius: 8px;
    margin-right: auto;
    padding: 4px 8px 4px 8px;
  `,
  rowWrapper: css<{ isMobile: boolean }>`
    ${({ isMobile }) =>
      !isMobile &&
      `
    flex-direction: row;
    justify-content: space-between;
    width: 294px;`};
  `
};
