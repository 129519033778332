import React, { useMemo } from 'react';
import pluralize from 'pluralize';
import { GFImage, StyledText, Link, EventSponsorshipView } from '@gf/cross-platform-lib/components';
import { School } from '@gf/cross-platform-lib/models';
import { Event, EventSeason, IEventSponsorship } from '@gf/cross-platform-lib/interfaces';
import { useEventInfo, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { eventDetailsPage, testProperties } from '@gf/cross-platform-lib/utils';
import { DEFAULT_SCHOOL_LOGO, MAX_CAPACITY_REMINDER } from '@gf/cross-platform-lib/constants';

import { styles } from './EventHeader.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { useFetchSchool } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { EventShareWidget } from '../EventShareWidget';

const EventHeaderContainer = styled.View`
  ${styles.eventHeaderContainer}
`;
const EventHeaderLeftSide = styled.View`
  ${styles.eventHeaderLeftSide}
`;
const EventHeaderRightSide = styled.View`
  ${styles.eventHeaderRightSide}
`;
const NumberOfEventsWrapper = styled.View`
  ${styles.numberOfEventsWrapper}
`;
const ScheduleWrapper = styled.View`
  ${styles.scheduleWrapper}
`;
const TicketsRemainingWrapper = styled.View`
  ${styles.ticketsRemainingWrapper};
`;
const RowWrapper = styled.View`
  ${styles.rowWrapper};
`;
interface EventHeaderProps {
  event: Event | EventSeason;
  school?: School;
  renewal?: boolean;
  sponsor?: IEventSponsorship;
  isFundraiser?: boolean;
}

export const EventHeader = ({ event, school, renewal = false, sponsor, isFundraiser = false }: EventHeaderProps) => {
  const { eventName, eventType, eventTypeDescription, ticketsRemainingText, ticketsRemaining, eventSchedule } =
    useEventInfo(event);
  const { isMobile: isMobileMedia, isNativeApp } = useMediaQuery();
  const isMobile = isMobileMedia || renewal;
  const { data } = useFetchSchool(event.schoolHuddleId ?? '');
  const eventSchool = data?.data ?? null;

  if (eventSchool) eventSchool.primaryColor = eventSchool.primaryColor ?? '000000';

  const isSeason = 'eventIds' in event ? true : false;
  const numberOfEvents =
    'eventIds' in event &&
    (event.eventIds?.length === 0
      ? 'No Events Scheduled Yet'
      : `${event.eventIds?.length} ${pluralize('event', event.eventIds?.length)} scheduled`);

  const {
    name: schoolName,
    huddleId: schoolHuddleId,
    logoUrl: schoolLogo = DEFAULT_SCHOOL_LOGO
  } = school || eventSchool || {};

  const { logoSize, eventTitle, eventNameTextType } = useMemo(() => {
    const logoSize = renewal ? [32] : [64, 104];
    const eventTitle = renewal ? `${schoolName || 'Gofan'}` : `${schoolName || 'Gofan'} Events`;
    const eventNameTextType = (function () {
      if (renewal) return 'body01SemiBold';
      return isMobile ? 'headingCompact02' : 'fluidHeading04';
    })();
    return {
      logoSize,
      eventTitle,
      eventNameTextType
    };
  }, [isMobile, renewal, schoolName]);

  return (
    <>
      <EventHeaderContainer renewal={renewal}>
        <EventHeaderLeftSide isMobile={isMobile}>
          <GFImage width={logoSize} height={logoSize} src={schoolLogo} />
        </EventHeaderLeftSide>
        <EventHeaderRightSide isMobile={isMobile}>
          {isMobile ? (
            renewal ? (
              <StyledText
                {...testProperties(eventDetailsPage.schoolName)}
                style={{ marginBottom: 8 }}
                typeToken='label01'
              >
                {eventTitle}
              </StyledText>
            ) : (
              <Link href={`/app/school/${schoolHuddleId}`}>
                <StyledText
                  {...testProperties(eventDetailsPage.schoolName)}
                  style={{ marginBottom: 8 }}
                  typeToken='label01'
                >
                  {eventTitle}
                </StyledText>
              </Link>
            )
          ) : (
            <Link href={`/app/school/${schoolHuddleId}`}>
              <StyledText {...testProperties(eventDetailsPage.schoolName)} style={{ marginBottom: 8 }}>
                {eventTitle}
              </StyledText>
            </Link>
          )}
          <StyledText
            style={{ marginBottom: isMobile ? 4 : 6 }}
            typeToken={eventNameTextType}
            {...testProperties(eventDetailsPage.eventName)}
          >
            {eventName}
          </StyledText>
          <StyledText
            style={{ marginBottom: isMobile ? 4 : 6 }}
            typeToken={renewal ? 'label01' : 'body01'}
            {...testProperties(eventDetailsPage.sport)}
          >
            {event.eventTypeName || eventType}
            {eventTypeDescription}
          </StyledText>
          <RowWrapper isMobile={isMobileMedia}>
            <ScheduleWrapper isMobile={isMobile} {...testProperties(eventDetailsPage.eventTime)}>
              <StyledText typeToken={renewal ? 'label01' : 'body01'}>{eventSchedule}</StyledText>
            </ScheduleWrapper>
            {isSeason ? (
              <NumberOfEventsWrapper isMobile={isMobileMedia}>
                <StyledText color='textInverse' textAlign='center' typeToken={'label01'}>
                  {numberOfEvents}
                </StyledText>
              </NumberOfEventsWrapper>
            ) : null}
          </RowWrapper>
          {ticketsRemaining > 0 && MAX_CAPACITY_REMINDER >= ticketsRemaining && (
            <TicketsRemainingWrapper>
              <StyledText typeToken={'label01'}>{`Only ${ticketsRemainingText} Left`}</StyledText>
            </TicketsRemainingWrapper>
          )}
          <EventSponsorshipView sponsor={sponsor} padding='12px 0' />
          {!isFundraiser && isNativeApp && (
            <EventShareWidget event={event} schoolName={schoolName!} isFundraiser={false} />
          )}
        </EventHeaderRightSide>
      </EventHeaderContainer>
    </>
  );
};
