import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  shareWidgetContainer: css<{ isMobile: boolean; isFundraiser: boolean }>`
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    height: ${({ isFundraiser }) => (isFundraiser ? '100%' : '')};
    align-items: ${({ isFundraiser }) => (isFundraiser ? 'center' : 'flex-start')};
    margin-vertical: ${({ isFundraiser }) => (isFundraiser ? '' : '8px')};
  `,
  textContainer: css`
    text-align: center;
  `,
  iconGroupContainer: css`
    flex-direction: row;
  `,
  iconContainer: css`
    margin-left: 16px;
  `
};
